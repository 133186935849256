import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ownProptypes from '../proptypes';

// import signUp from '../../../Functions/Users';

import Button from '../Button';
import Input from '../Fields/Input';
import Email from '../Fields/Email';
import Password from '../Fields/Password';
import Phone from '../Fields/Phone';

const columnStyles = { minHeight: '100px' };

class SignUp extends Component {
  constructor(props) {
    super(props);
    // initialize state to hold validity of form fields
    this.state = {
      first: false,
      last: false,
      phone: false,
      email: false,
      password: false,
    };
  }

  // higher-order function that returns a state change
  // watch function

  // sets the corresponding state property to true if
  // the form field has no errors
  fieldStateChanged = ({ field }) => state => (
    this.setState({
      [field]: state.errors.length === 0
    })
  );

  // state change watch functions for each field
  firstChanged = this.fieldStateChanged({ field: 'first' });
  lastChanged = this.fieldStateChanged({ field: 'last' });
  phoneChanged = this.fieldStateChanged({ field: 'phone' });
  emailChanged = this.fieldStateChanged({ field: 'email' });
  passwordChanged = this.fieldStateChanged({ field: 'password' });

  // onClick = props => signUp(props);
  onClick = props => console.log(props);

  render() {
    const { id, title, styles } = this.props;
    const {
      first,
      last,
      phone,
      email,
      password,
    } = this.state;
    const formValidated = (
      first &&
      last &&
      phone &&
      email &&
      password
    );

    const validate = value => value.length > 1;

    return (
      <div className="join-form">
        <form noValidate>
          <div className="row card" style={styles}>
            { title && (
              <div className="col s12">
                {/** Render the title component **/}
                <h5>{ title }</h5>
              </div>
            )}
            <div className="col s12 m6" style={columnStyles}>
              <Input
                type="text"
                fieldId={`${id}-firstJoin`}
                label="First Name"
                validator={validate}
                onStateChanged={this.firstChanged}
                required
              />
            </div>
            <div className="col s12 m6" style={columnStyles}>
              <Input
                type="text"
                fieldId={`${id}-lastJoin`}
                label="Last Name"
                validator={validate}
                onStateChanged={this.lastChanged}
                required
              />
            </div>
            <div className="col s12 m6" style={columnStyles}>
              <Phone
                label="Phone Number"
                fieldId={`${id}-phoneJoin`}
                onStateChanged={this.phoneChanged}
                required
              />
            </div>
            <div className="col s12 m6" style={columnStyles}>
              {/** Render the email field component **/}
              <Email
                label="Email"
                fieldId={`${id}-emailJoin`}
                onStateChanged={this.emailChanged}
                autocomplete="email"
                required
              />
            </div>
            <div className="col s12 m6" style={columnStyles}>
              {/**
                Render the password field component using
                thresholdLength of 7 and minStrength of 3
              **/}
              <Password
                label="Password"
                fieldId={`${id}-passwordJoin`}
                onStateChanged={this.passwordChanged}
                autocomplete="new-password"
                showStrength={true}
                required
              />
            </div>
            <div className="col s12" style={columnStyles}>
              {/** Show the form button only if all fields are valid **/}
              { formValidated && (
                  <Button
                    title="Sign Up"
                    onClick={() => this.onClick({
                      first,
                      last,
                      phone,
                      email,
                      password,
                    })}
                  />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SignUp.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  styles: ownProptypes.typeCheck.obj,
};

SignUp.defaultProps = {
  styles: {},
};

export default SignUp;
