import React from 'react';

import Address from './Address';

const Info = ({
  title,
  name,
  username,
  firstname,
  lastname,
  email,
  phone,
  address,
  labels,
}) => (
  <div className="row" style={{ margin: '0' }}>
    <div
      className={`ttyl-grey col s12 ${!!address ? 'm6' : ''}`}
      style={{ padding: '20px' }}
    >
      {
        title
          ?
          (<div><h5>{ title }</h5></div>)
          :
          null
      }
      {
        name
          ?
          (
            <div className="collection-item">
              { labels && <span><b>Name: </b></span> }
              { name }
            </div>
          )
          :
          null
      }
      {
        username
          ?
          (
            <div className="collection-item">
              { labels && <span><b>Username: </b></span> }
              { username }
            </div>
          )
          :
          null
      }
      {
        firstname
          ?
          (
            <div className="collection-item">
              { labels && <span><b>First Name: </b></span> }
              { firstname }
            </div>
          )
          :
          null
      }
      {
        lastname
          ?
          (
            <div className="collection-item">
              { labels && <span><b>Last Name: </b></span> }
              { lastname }
            </div>
          )
          :
          null
      }
      {
        email
          ?
          (
            <div className="collection-item">
              { labels && <span><b>Email: </b></span> }
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          )
          :
          null
      }
      {
        phone
          ?
          (
            <div className="collection-item">
              { labels && <span><b>Phone: </b></span> }
              <a href={`tel:${phone}`}>{ phone }</a>
            </div>
          )
          :
          null
      }
    </div>
    { address ? (<Address labels={labels} { ...address } />) : null }
  </div>
);

Info.displayName = 'Info';

export default Info;
