import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import FUNCTIONS from './Functions';

import Navigation from './Modules/Navigation';
import Home from './Modules/Home';
import Shows from './Modules/Shows';
import Account from './Modules/Account';
import Contact from './Modules/Contact';
import UserPortal from './Modules/UserPortal';
import Footer from './Modules/Footer';
import NotFound from './Modules/NotFound';
import News from './Modules/News';
import Terms from './Modules/Terms';

import Preloader from './Modules/components/Preloader';
import Subscribe from './Modules/components/Mailchimp/Subscribe';
import Promote from './Modules/components/Mailchimp/Promote';

const { NAV } = FUNCTIONS;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: false };
  }
  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);
      NAV.hideSideNav();
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    return (
      <Fragment>
        <Navigation
          history={{
            location: this.props.history.location,
            action: this.props.history.action,
          }}
          auth={this.state.auth}
        />
        <Subscribe />
        <Promote />
        <Preloader id="AppLoader" />
        <div
          id="root-body"
          className="container white-text"
          style={{ display: 'none'}}
        >
          {
            /**
            *  route to diffrent component
            */
          }
          <Switch>
            <Route
              exact
              path="/"
              component={Home}
              state={this.state}
            />
            <Route
              exact
              path="/home"
              component={Home}
              state={this.state}
            />
            <Route
              exact
              path="/news"
              component={() => (
                <News auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/shows"
              component={() => (
                <Shows auth={this.state.auth} header={true} />
              )}
              state={this.state}
            />
            <Route
              path="/show/:id"
              component={({ match }) => (
                <Shows
                  auth={this.state.auth}
                  id={
                    (!!match && !!match.params && !!match.params.id)
                      ?
                      match.params.id
                      :
                      false
                  }
                />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/logInOrSignUp"
              component={() => (
                <UserPortal auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/account"
              component={() => (
                <Account auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/contact"
              component={() => (
                <Contact auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              exact
              path="/terms"
              component={() => (
                <Terms auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Route
              component={() => (
                <NotFound auth={this.state.auth} />
              )}
              state={this.state}
            />
            <Redirect to="home" />
          </Switch>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({}),
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({}),
    action: PropTypes.string,
  }),
};

App.defaultProps = {
  location: {},
};

export default withRouter(App);
