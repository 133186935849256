import React, { Fragment } from 'react';

import { Wave } from 'react-animated-text';

const Text = ({
  children,
  classNames,
  styles,
  title,
  label,
  animate = false,
}) => (
  <Fragment>
    {
      !children && (title || label)
        ?
        <div className={classNames} style={styles}>
          <Wave
            text={title || label}
            effect="verticalFadeIn"
            effectChange={10}
            effectDirection='up'
          />
        </div>
        :
        <div className={classNames} style={styles}>
          { children }
        </div>
    }
  </Fragment>
);

export default Text;
