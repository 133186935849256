import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import signUp from '../../../Functions/Users';

import Button from '../Button';
import Email from '../Fields/Email';
import Input from '../Fields/Input';
import Phone from '../Fields/Phone';

class Create extends Component {
  constructor(props) {
    super(props);
    // initialize state to hold validity of form fields
    this.state = {
      first: false,
      last: false,
      url: false,
      phone: false,
      email: false,
      location: false,
    };
  }

  // higher-order function that returns a state change
  // watch function

  // sets the corresponding state property to true if
  // the form field has no errors
  fieldStateChanged = ({ field }) => state => (
    this.setState({
      [field]: state.errors.length === 0
    })
  );

  // state change watch functions for each field
  firstChanged = this.fieldStateChanged({ field: 'first' });
  lastChanged = this.fieldStateChanged({ field: 'last' });
  urlChanged = this.fieldStateChanged({ field: 'url' });
  phoneChanged = this.fieldStateChanged({ field: 'phone' });
  emailChanged = this.fieldStateChanged({ field: 'email' });
  locationChanged = this.fieldStateChanged({ field: 'location' });

  // onClick = props => signUp(props);
  onClick = props => console.log(props);

  render() {
    const { id, classNames, styles } = this.props;
    const {
      first,
      last,
      url,
      phone,
      email,
      location,
    } = this.state;
    const formValidated = (
      first &&
      last &&
      url &&
      phone &&
      email &&
      location
    );

    const validate = value => value.length > 1;

    return (
      <div className={classNames || 'create-form'}>
        <form noValidate>
          <div
            className="row grey darken-4 raised"
            style={styles || { margin: 0, padding: '20px' }}
          >
            <div className="col s12">
              {/** Render the title component **/}
              <h5>Create An Artist</h5>
            </div>
            <div className="col s12 m6">
              <Input
                type="text"
                fieldId={`${id}-firstJoin`}
                label="First Name"
                validator={validate}
                onStateChanged={this.firstChanged}
                required
              />
            </div>
            <div className="col s12 m6">
              <Input
                type="text"
                fieldId={`${id}-lastJoin`}
                label="Last Name"
                validator={validate}
                onStateChanged={this.lastChanged}
                required
              />
            </div>
            <div className="col s12 m6">
              <Input
                type="text"
                fieldId={`${id}-urlJoin`}
                label="Website or URL of the Artist"
                validator={validate}
                onStateChanged={this.urlChanged}
                required
              />
            </div>
            <div className="col s12 m6">
              <Phone
                label="Phone Number"
                fieldId={`${id}-phoneJoin`}
                onStateChanged={this.phoneChanged}
                required
              />
            </div>
            <div className="col s12 m6">
              {/** Render the email field component **/}
              <Email
                label="Email"
                fieldId={`${id}-emailJoin`}
                onStateChanged={this.emailChanged}
                autocomplete="email"
                required
              />
            </div>
            <div className="col s12 m6">
              <Input
                type="text"
                fieldId={`${id}-lastJoin`}
                label="Location of the Artist"
                validator={validate}
                onStateChanged={this.locationChanged}
                required
              />
            </div>
            <div className="col s12">
              {/** Show the form button only if all fields are valid **/}
              { formValidated && (
                  <Button
                    title="Create An Artist"
                    onClick={() => this.onClick({
                      first,
                      last,
                      url,
                      phone,
                      email,
                      location,
                    })}
                  />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Create.propTypes = {
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  styles: PropTypes.shape({}),
};

export default Create;
