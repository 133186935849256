import React from 'react';

import Shows from './Shows';
import Welcome from './Welcome';

const Home = ({ icon, auth }) => (
  <section id="Home">
    <Welcome />
    <Shows header={true} />
  </section>
);

Home.displayName = 'Home';

export default Home;
