import axios from 'axios';

const API_KEY = '69a8caa4242342c58a2edb5234ed015d';

const API_DOMAIN = 'https://newsapi.org/v2';

const URLS = {
  headlines: ({ domain, q, source, key }) => (
    `${domain}/top-headlines${
      q ? `?q=${q}&` : '?'
    }sources=${source}&apiKey=${key}`
  ),
  recent: ({ domain, q, source, key }) => (
    `${domain}/everything${
      q ? `?q=${q}&` : '?'
    }sources=${source}&apiKey=${key}`
  ),
};

const CONFIG = {
  API_DOMAIN,
  API_KEY,
  URLS,
};

const getNewsHeadlines = ({ clientid, source, q }) => (
  axios.get(
    CONFIG.URLS.headlines({
      domain: CONFIG.API_DOMAIN,
      source,
      q,
      key: CONFIG.API_KEY }))
);

const getNewsRecent = ({ clientid, source, q }) => (
  axios.get(
    CONFIG.URLS.recent({
      domain: API_DOMAIN,
      source,
      q,
      key: API_KEY }))
);

export default {
  getNewsHeadlines,
  getNewsRecent,
};
