import React from 'react';

const Loader = ({ id, color, type, style, view }) => (
  <div id={id} className="center-align" style={{ height: '125vh', width: '50%' }}>
    <div
      className={`progress ${color ? color : 'blue accent-1'}`}
      style={{ top: '35vh', position: 'relative', left: '50%' }}
    >
      <div
        className={type ? 'determinate' : 'indeterminate'}
        style={style}
      />
    </div>
  </div>
);

export default Loader;
