import React from 'react';

import Create from './components/Forms/CreateArtist';

const Artist = () => (
  <section id="Artist">
    <Create id="ArtistPortalCreateArtist" />
  </section>
);

export default Artist;
