import React from 'react';
import PropTypes from 'prop-types';
import isMobilePhone from 'validator/lib/isMobilePhone';

import Input from './Input';

const throwError = () => {
  throw new Error('Phone Number is invalid')
};

const Phone = ({
  label,
  fieldId,
  required,
  children,
  onStateChanged,
}) => (
  <Input
    type="tel"
    label={label}
    fieldId={fieldId}
    required={required}
    validator={(value) => (
        isMobilePhone(value) ? true : throwError()
    )}
    onStateChanged={onStateChanged}
  >
    {children}
  </Input>
);

Phone.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  onStateChanged: PropTypes.func.isRequired,
};

export default Phone;
