import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ownProptypes from '../proptypes';

import Button from '../Button';
import Email from '../Fields/Email';
import Password from '../Fields/Password';

class SignIn extends Component {
  // initialize state to hold validity of form fields
  state = { email: false, password: false }

  // higher-order function that returns a state change watch function
  // sets the corresponding state property to true if the form field has no errors
  fieldStateChanged = field => state => this.setState({ [field]: state.errors.length === 0 });

  // state change watch functions for each field
  emailChanged = this.fieldStateChanged('email');
  passwordChanged = this.fieldStateChanged('password');

  onClick = props => console.log(props);

  render() {
    const { id, title, styles } = this.props;
    const { email, password } = this.state;
    const formValidated = email && password;

    return (
      <div className="sign-in-form">
        <form noValidate>
          <div className="row card" style={styles}>
            { title && (
              <div className="col s12">
                {/** Render the title component **/}
                <h5>{ title }</h5>
              </div>
            )}
            <div className="col s12 m6">
              {/** Render the email field component **/}
              <Email
                fieldId={`${id}-emailSignIn`}
                label="Email"
                placeholder="Enter Email Address"
                onStateChanged={this.emailChanged}
                autocomplete="email"
              />
            </div>
            <div className="col s12 m6">
              {/** Render the password field component using thresholdLength of 7 and minStrength of 3 **/}
              <Password
                fieldId={`${id}-passwordSignIn`}
                label="Password"
                placeholder="Enter Password"
                onStateChanged={this.passwordChanged}
                autocomplete="current-password"
                showStrength={false}
              />
            </div>
            <div className="col s12">
              {/** Show the form button only if all fields are valid **/}
              { formValidated && (
                <Button
                  title="Log In"
                  onClick={() => this.onClick({ email, password })}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SignIn.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  styles: ownProptypes.typeCheck.obj,
  children: PropTypes.node,
};

SignIn.defaultProps = {
  styles: {},
  children: '',
};

export default SignIn;
