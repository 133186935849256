import React from 'react';

import Create from './components/Forms/CreatePromoter';

const Promoter = () => (
  <section id="Promoter">
    <Create id="PromoterPortalCreatePromoter" />
  </section>
);

export default Promoter;
