/* eslint-disable */
import axios from 'axios';

const presentShows = [
];

const pastShows = [
  {
    showid: 'quantum-2020-02-28-allabouthouse-bellecour',
    genres: ['Future House', 'Bass House', 'Deep House'],
    host: 'TTYL X 2Pull',
    title: 'All About House - Bellecour',
    venue: {
      name: 'Quantum Brooklyn',
      location: '177 2nd Ave, Brooklyn, NY 11215',
      website: 'https://www.quantumbk.com/',
    },
    flyer: (
      `https://storage.googleapis.com/ttylevents-cdn/events/2020Bellacour228/ttyl-bellecour-sq.jpg`
    ),
    artists: [
      {
        name: 'Bellecour',
        website: 'https://soundcloud.com/bellecour',
      },
      {
        name: 'TEK',
        website: 'https://soundcloud.com/tekbeatzmusic',
      },
    ],
    datetime: '02/28/2020',
    posted: '02/01/2020',
    tickets: {
      enableTiers: false,
      enableVip: false,
      tiers: false,
    },
    description: 'Rising Stars house producers, Bellecour makes their debut for All About House at Quantum Brooklyn.',
    disclaimer: 'You must be 18 or older to attend this event.',
  },
  {
    showid: 'schimanskinyc-2019-06-01-zhu',
    genres: ['Deep House', 'Progressive House', 'Tech House'],
    host: 'Gov Ball After Dark',
    title: 'Zhu Presents: "Zhudio 54"',
    venue: {
      name: 'Schimanski NYC',
      location: '54 N 11th St., Brooklyn, NY 11249',
      website: 'https://www.schimanskinyc.com/calendar/2019/4/16/zhu',
    },
    flyer: (
      `https://images.squarespace-cdn.com/content/v1/5806316e1b631b4e1897b9ee/1555444957140-2ROU3LFN5L8A0R8439NA/ke17ZwdGBToddI8pDm48kPW9mM0LEH4nwJV6CaA-mC17gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1Ue0SfeZ-2NefZ0LdCzY4rf26TTQovOpEIhtVZa4g_S3uB0IPquf7l3-u9rEP9kgjtw/ZHU_GovBall_promo_FBevent_final.jpg?format=1500w`
    ),
    artists: [
      {
        name: 'Zhu',
        website: 'https://soundcloud.com/zhu',
      },
      {
        name: 'PartyWithRay',
        website: 'https://soundcloud.com/partywithray',
      },
      {
        name: 'TEK',
        website: 'https://soundcloud.com/tekbeatzmusic',
      },
    ],
    datetime: '06/01/2019',
    posted: '05/27/2019',
    tickets: {
      enableTiers: false,
      enableVip: false,
      tiers: false,
    },
    description: 'Schimanski is excited to welcome Zhu to Schimanski for his very special "Zhudio 54" concept. As a part of the Governors Ball "After Dark" series, Zhu will invade North 11th for a very special appearance.',
    disclaimer: 'You must be 21 or older to attend this event.',
    purchase: {
      soldOut: true,
    },
    // https://www.eventbrite.com/e/gov-ball-after-dark-zhu-presents-zhudio-54-tickets-60441913390
  },
];

const eventbriteToken = 'E5TWYKRSZL3L5BO5TT7R';

const buildUrlOld = path => (
  `https://us-central1-seanvazquez-project.cloudfunctions.net/${path}`
);

const clientid = 'ttylevents-app';

const getOldShows = ({ action, attributes }) => (
  axios.post(
    buildUrlOld('events'),
    { clientid, action: (action || 'find'), attributes }
  ).then(({ data: [sendThis] }) => sendThis).catch((err) => err)
);

const getEventBrite = () => (
  axios.get(
    `https://www.eventbriteapi.com/v3/users/me/?token=${eventbriteToken}`
  ).then(({ data }) => ({ eventBrite: data })).catch((err) => err)
);

const getShows = () => (
  Promise.all([
    // Josh Wink
    // getOldShows({ attributes: { eventid: 'joshwink420' } }),
    Promise.resolve(presentShows),
    Promise.resolve(pastShows),
    // CID
    // getOldShows({ attributes: { eventid: '5c6cc46ffb6fc01c4ce810ae' } }),

    // Eventbrite
    // getEventBrite(),
  ])
);

export default {
  getShows,
  getEventBrite,
  getOldShows,
};
