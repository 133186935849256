import React from 'react';

import Anchor from './components/Anchor';
import Button from './components/Button';

import Routing from '../Routing';

const COMPONENT_NAME = 'NonAuthRender';

const NonAuthRender = ({ auth, user }) => (
  <section id={COMPONENT_NAME} className="row container" style={{ padding: '1rem' }}>
    <div className="col s12 uppercase">
      <h3>You are unauthorized to see this content!</h3>
    </div>
    <div className="col s12 uppercase">
      <p className="bolded">
        We could not validate your access for this content!
      </p>
      <p>
        Please click the back button on your browser,
        or click a navigation button below:
      </p>
    </div>
    { !auth && (
      <div className="col s12 m6 l4 uppercase">
        <Anchor
          id={`LinkTo-${COMPONENT_NAME}-logInOrSignUp`}
          classNames="btn btn-primary fluid"
          title="Login Or Sign Up"
          styles={{ margin: '5px 0' }}
          to="logInOrSignUp"
        />
      </div>
    )}
    <div className="col s12 m6 l4 uppercase">
      <Button
        id={`NavigateTo-${COMPONENT_NAME}-back`}
        classNames="btn btn-primary fluid"
        title="Go Back"
        styles={{ margin: '5px 0' }}
        onClick={() => Routing.goBack()}
      />
    </div>
  </section>
);

NonAuthRender.displayName = COMPONENT_NAME;

export default NonAuthRender;
