import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Fields/Input';
import isEmail from 'validator/lib/isEmail';

const throwError = () => {
  throw new Error('Email Address is invalid')
};

const Email = ({
  label,
  fieldId,
  styles,
  required,
  children,
  onStateChanged,
  autocomplete,
}) => (
  <Input
    type="email"
    label={label}
    fieldId={fieldId}
    styles={styles}
    validator={(value) => (
      isEmail(value) ? true : throwError()
    )}
    onStateChanged={onStateChanged}
    autocomplete={autocomplete}
  >
    {children}
  </Input>
);

Email.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  onStateChanged: PropTypes.func.isRequired,
};

Email.defaultProps = {
  required: false,
  children: '',
};

export default Email;
