import React from 'react';

import Charts from './components/Charts';
import Icon from './components/Icon';

const Analytics = () => (
  <section id="Analytics">
    <div className="row">
      <div className="col s12 m6 l4">
        <div className="card">
          <Charts title="Last Month's Sales" />
        </div>
      </div>
      <div className="col s12 m6 l4">
        <div className="card">
          <Charts title="Last Years's Sales" />
        </div>
      </div>
      <div className="col s12 m6 l4">
        <div className="card">
          <Charts title="Total Sales" type="pie" />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col s12 m6 l4">
        <div className="card">
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                572
                <Icon icon="arrow_upward" />
              </span>
              <span>Month to Date Engagement</span>
            </p>
          </div>
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                137
                <Icon icon="arrow_upward" />
              </span>
              <span>Month to Date New Engagement</span>
            </p>
          </div>
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                39.23%
                <Icon icon="arrow_upward" />
              </span>
              <span>Month to Date Percentage Conversion</span>
            </p>
          </div>
        </div>
      </div>
      <div className="col s12 m6 l4">
        <div className="card">
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                4,572
                <Icon icon="arrow_upward" />
              </span>
              <span>Year to Date Engagement</span>
            </p>
          </div>
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                567
                <Icon icon="arrow_upward" />
              </span>
              <span>Year to Date New Engagement</span>
            </p>
          </div>
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                32.33%
                <Icon icon="arrow_upward" />
              </span>
              <span>Year to Date Percentage Conversion</span>
            </p>
          </div>
        </div>
      </div>
      <div className="col s12 m6 l4">
        <div className="card">
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                54,572
                <Icon icon="arrow_upward" />
              </span>
              <span>Total Engagement</span>
            </p>
          </div>
          <div className="card-content">
            <p>
              <span className="card-title green-text">
                21.73%
                <Icon icon="arrow_upward" />
              </span>
              <span>Total Percentage Conversion</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Analytics;
