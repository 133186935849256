import React, { PureComponent } from 'react';

import Tabs from './components/Tabs';
import SignUp from './components/Forms/SignUp';
import SignIn from './components/Forms/SignIn';


const COMPONENT_NAME_1 = 'SignUp';

const Register = ({ id }) => (
  <section id={COMPONENT_NAME_1}>
    <div className="row">
      <SignUp
        id={id}
        title="Register"
      />
    </div>
  </section>

);

SignUp.displayName = COMPONENT_NAME_1;

const COMPONENT_NAME_2 = 'Log In';

const Login = ({ id }) => (
  <section id={COMPONENT_NAME_2}>
    <div className="row">
      <SignIn
        id={id}
        title="Log In"
      />
    </div>
  </section>
);

Login.displayName = COMPONENT_NAME_2;

const COMPONENT_NAME = 'UserPortal';

class UserPortal extends PureComponent {
  render() {
    const tabsProps = [
      {
        icon: 'add',
        title: 'Register',
        child: (
          <div className="col s12">
            <Register id="UserPortal-SignUp-mobile" />
          </div>
        ),
        selected: true,
        disabled: false,
      },
      // {
      //   icon: 'account',
      //   title: 'Log In',
      //   child: (
      //     <div className="col s12">
      //       <Login id="UserPortal-SignIn-mobile" />
      //     </div>
      //   ),
      //   selected: false,
      //   disabled: false,
      // },
    ];
    return (
      <section data-module-name={COMPONENT_NAME}>
        <div className="uppercase">
          <div className="row">
            {/* Mobile Version */}
            <div className="col s12 hide-on-large-only">
              <Tabs id="MobileSignUpTabs" tabs={tabsProps} />
            </div>
            {/* Desktop Version */}
            <div className="col l6 hide-on-med-and-down">
              <Register id="UserPortal-SignUp-desktop" />
            </div>
            {/* <div className="col l6 hide-on-med-and-down">
              <Login id="UserPortal-SignIn-desktop" />
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default UserPortal;
