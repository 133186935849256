import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';

import Input from './Input';

class Password extends PureComponent {
  constructor(props) {
    super(props);
    const { minStrength = 3, thresholdLength = 7 } = props;
    // set default minStrength to 3 if not a number or not specified
    // minStrength must be a a number between 0 - 4
    this.minStrength = (
      typeof minStrength === 'number'
        ?
        Math.max(Math.min(minStrength, 4), 0)
        :
        3
      );

    // set default thresholdLength to 7 if not a number or not specified
    // thresholdLength must be a minimum value of 7
    this.thresholdLength = (
      typeof thresholdLength === 'number'
        ?
        Math.max(thresholdLength, 7)
        :
        7
    );

    // initialize internal component state
    this.state = { password: '', strength: 0 };
  }

  stateChanged = state => {
    // update the internal state using the updated state from
    // the input field
    this.setState(
      {
        password: state.value,
        strength: zxcvbn(state.value).score
      },
      () => this.props.onStateChanged(state)
    );
  };

  validatePasswordLength = value => {
    // ensure password is long enough
    if (value.length <= this.thresholdLength) {
      throw new Error("Password is short");
    }
  };

  validatePasswordStrong = value => {
    this.validatePasswordLength(value);
    // ensure password is strong enough using the zxcvbn library
    if (zxcvbn(value).score < this.minStrength) {
      throw new Error("Password is weak");
    }
  };

  render() {
    const {
      label,
      fieldId,
      children,
      showStrength,
      autocomplete,
    } = this.props;
    const { password, strength } = this.state;

    const passwordLength = password.length;
    const passwordStrong = strength >= this.minStrength;
    const passwordLong = passwordLength > this.thresholdLength;

    // dynamically set the password length counter class
    const counterClass = (
      [
        'badge badge-pill',
        passwordLong
          ?
          (passwordStrong ? 'badge-success' : 'badge-warning')
          :
          'badge-danger'
      ].join(' ').trim()
    );

    const strengthBar = strength => ({
      width: `${Number(strength * 0.01 * 25) * 100}%`
    });

    const validate = (
      !!showStrength
        ?
        (this.validatePasswordStrong)
        :
        (this.validatePasswordLength)
    );

    return (
      <div className="password-field">
        <div className="row">
          {/** Pass the validation and stateChanged functions as props to the form field **/}
          <Input
            type="password"
            label={label}
            fieldId={fieldId}
            validator={validate}
            onStateChanged={this.stateChanged}
            autocomplete={autocomplete}
            required
          >
            {/*
              <span className="d-block form-hint">To conform with our Strong Password policy, you are required to use a sufficiently strong password. Password must be more than 7 characters.</span>
            */}
            {children}
          </Input>
        </div>
        { showStrength && (
          <div className="row">
            <div className="row">
              <div className="col s9">
                {/** Render the password strength meter **/}
                <div className="progress">
                  <div
                    className="determinate"
                    data-strength={strength}
                    style={strengthBar(strength)}
                  />
                </div>
              </div>
              <div className="col s3">
                {/** Render the password length counter indicator **/}
                <span
                  className={counterClass}
                >
                  {(
                    passwordLength
                      ?
                      (passwordLong ? `${this.thresholdLength}+` : passwordLength)
                      :
                      ''
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

}

Password.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  showStrength: PropTypes.bool,
  children: PropTypes.node,
  autocomplete: PropTypes.string,
};

Password.defaultProps = {
  showStrength: false,
  children: '',
};

export default Password;
