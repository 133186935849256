import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ownPropTypes from './proptypes';

import Icon from './Icon'
import Preloader from './Preloader';

class Tabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { tabs: {}, loading: true };
  }

  componentDidMount() {
    if (!!global.$) {
      global.$('ul.tabs').tabs();
      this.setState({
        tabs: this.props.tabs.map(tab => tab),
        loading: false,
      });
    }
  }

  showContent = id => {
    this.setState({
      tabs: this.state.tabs.map(tab => ({
        ...tab,
        selected: id === tab.title,
      })),
    });
  };

  isShown = tab => {
    return (
      !!tab && tab.selected === true
        ?
        ''
        :
        'hide'
    );
  };

  render() {
    const { id, styles } = this.props;
    const { tabs, loading } = this.state;
    return(
      loading
        ?
        <Preloader />
        : (
          <div id={id} className="row" style={{ margin: '0', ...styles.container}}>
            <div
              className="col s12"
              style={{ padding: '0', ...styles.tabs }}
            >
              <ul className="tabs" style={styles.tabsList}>
                { !!tabs && tabs.map((tab, index) => (
                  <li
                    className={
                      `tab col ${
                        tab.disabled ? 'disabled' : ''
                      } ${
                        tab.selected ? 'active' : ''
                      }`
                    }
                    key={`${tab.title}-{index}-tab`}
                  >
                    <a
                      id={`#${tab.title}-content`}
                      href={`#${tab.title}-content`}
                      onClick={() => this.showContent(tab.title)}
                      style={{ cursor: 'pointer' }}
                      className="waves-effect waves-light"
                    >
                      <span className="hide-on-small-only">
                        {tab.title || tab.header}
                      </span>
                      <span className="hide-on-med-and-up">
                        <Icon icon={tab.icon} />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            { !!tabs && tabs.map((tab) => (
              <div
                id={`#${tab.title}`}
                className={`col s12 ${this.isShown(tab)}`}
                style={
                  styles.childContainer || { margin: 0, padding: 0 }
                }
                key={`${tab.title}-{index}-content`}
              >
                {!tab.disabled && tab.child}
              </div>
            ))}
          </div>
        )
    );
  }
}

Tabs.propTypes = {
  id: ownPropTypes.typeCheck.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: ownPropTypes.typeCheck.string,
      header: ownPropTypes.typeCheck.children,
      child: ownPropTypes.typeCheck.children,
      disabled: PropTypes.bool,
    })
  ),
  styles: PropTypes.shape({}),
};

Tabs.defaultProps = {
  tabs: false,
  styles: {},
};

export default Tabs;
