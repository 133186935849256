import React from 'react';

import Create from './components/Forms/CreateVenue';

const Venue = () => (
  <section id="Venue">
    <Create id="VenuePortalCreateVenue" />
  </section>
);

export default Venue;
