import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import signUp from '../../../Functions/Users';

import Button from '../Button';
import Input from '../Fields/Input';

class Manage extends Component {
  constructor(props) {
    super(props);
    // initialize state to hold validity of form fields
    this.state = {
      host: false,
      title: false,
      flyer: false,
      artists: false,
      datetime: false,
      tickets: false,
      description: false,
      location: false,
      disclaimer: 'You must be 21 or older to attend this event.',
    };
  }

  // higher-order function that returns a state change
  // watch function

  // sets the corresponding state property to true if
  // the form field has no errors
  fieldStateChanged = ({ field }) => state => (
    this.setState({
      [field]: state.errors.length === 0
    })
  );

  // state change watch functions for each field
  hostChanged = this.fieldStateChanged({ field: 'host' });
  titleChanged = this.fieldStateChanged({ field: 'title' });
  flyerChanged = this.fieldStateChanged({ field: 'flyer' });
  artistsChanged = this.fieldStateChanged({ field: 'artists' });
  datetimeChanged = this.fieldStateChanged({ field: 'datetime' });
  ticketsChanged = this.fieldStateChanged({ field: 'tickets' });
  descriptionChanged = this.fieldStateChanged({ field: 'description' });
  locationChanged = this.fieldStateChanged({ field: 'location' });
  disclaimerChanged = this.fieldStateChanged({ field: 'disclaimer' });

  // onClick = props => signUp(props);
  onClick = props => console.log(props);

  render() {
    const { typeForm, id, classNames, styles } = this.props;
    const {
      host,
      title,
      flyer,
      artists,
      datetime,
      tickets,
      description,
      location,
      disclaimer,
    } = this.state;
    const formValidated = (
      typeForm &&
      host &&
      title &&
      flyer &&
      artists &&
      datetime &&
      tickets &&
      description &&
      location &&
      disclaimer
    );

    const validate = value => value.length > 1;

    return (
      typeForm
        ?
        (
          <div className={classNames || 'manage-form'}>
            <form noValidate>
              <div
                className="row grey darken-4 raised"
                style={styles || { margin: 0, padding: '20px' }}
              >
                <div className="col s12">
                  {/** Render the title component **/}
                  <h5>{typeForm}</h5>
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-host`}
                    label="Host of the Event"
                    validator={validate}
                    onStateChanged={this.hostChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-title`}
                    label="Title of the Event"
                    validator={validate}
                    onStateChanged={this.titleChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-flyer`}
                    label="Flyer of the Event"
                    validator={validate}
                    onStateChanged={this.flyerChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-artists`}
                    label="Artists Performing at the Event"
                    validator={validate}
                    onStateChanged={this.artistsChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-datetime`}
                    label="Time of the Event"
                    validator={validate}
                    onStateChanged={this.datetimeChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-description`}
                    label="Description of the Event"
                    validator={validate}
                    onStateChanged={this.descriptionChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-disclaimer`}
                    label="Disclaimer of the Event"
                    validator={validate}
                    onStateChanged={this.disclaimerChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  <Input
                    type="text"
                    fieldId={`${id}-${typeForm}-location`}
                    label="Address of the Event"
                    validator={validate}
                    onStateChanged={this.locationChanged}
                    required
                  />
                </div>
                <div className="col s12 m6">
                  {/* Add tickets */}
                </div>
                <div className="col s12">
                  {/** Show the form button only if all fields are valid **/}
                  { formValidated && (
                      <Button
                        title={typeForm}
                        onClick={() => this.onClick({
                          host,
                          title,
                          flyer,
                          artists,
                          datetime,
                          tickets,
                          description,
                          disclaimer,
                        })}
                      />
                  )}
                </div>
              </div>
            </form>
          </div>
        )
        :
        null
    );
  }
}

Manage.propTypes = {
  typeForm: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  classNames: PropTypes.string,
  styles: PropTypes.shape({}),
};

export default Manage;
