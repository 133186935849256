import React, { memo } from 'react';

import Text from './components/Text';
import Video from './components/Video';

const COMPONENT_NAME = 'Welcome';

const Welcome = () => (
  <section id={`${COMPONENT_NAME}-View`} className="black pad margin-t center raised">
    <p className="flow-text">
      <Text title="Experience" /><b className="green-text">TEK</b>.
    </p>
    {false && (
      <Video
        src="https://storage.googleapis.com/tekbeatz-cdn/assets/videos/tekdneundefined/DownWeGoPromoSmall.mp4"
        poster="https://storage.googleapis.com/tekbeatz-cdn/assets/images/tek_logo_recursion.jpg"
      />
    )}
    <p className="font-size-2">
      <Text>
        <b className="font-size-3 green-text">
          TEK
        </b> has been a musician since age 4.
          As a software engineer,
          he utilizes music and technology
            to power his TEK helm,
              allowing his audience to
                control the lighting from
                the crowd. TEK bridges
                  technology and music
                  to create an immersive
                experience.
      </Text>
    </p>
    <p className="font-size-2">
      <Text>
        Not only does he merge technology and music during his
          performances but, he plays multiple instruments to
          reconstruct his songs during his live performances.
            He also DJs of course ;).
      </Text>
    </p>
    <p><Text>
      Please sign up above and check back for music and upcoming shows.
    </Text></p>
  </section>
);

export default memo(Welcome);
