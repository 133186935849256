import React from 'react';

import Accordion from  './components/Accordion';
import Anchor from  './components/Anchor';
import HeaderBar from  './components/HeaderBar';
import Icon from  './components/Icon';

const items = [
  {
    header: false,
    child: (
      <p className="pad">
        {`
          TEK and tekbeatz have multiple branches of business. Please look below for
          specific topics for contacting us.
        `}
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Management
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToSupport"
          href="mailto:mgmt@tekbeatz.com"
          title="mgmt@tekbeatz.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Support
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToSupport"
          href="mailto:support@tekbeatz.com"
          title="support@tekbeatz.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Promos or Demos
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToPromo"
          href="mailto:info@ttylevents.com"
          title="promo@tekbeatz.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },
  {
    header: (
      <h5>
        <Icon icon="chevron_right" classNames="margin-r font-size-2" />
        Technology Ideas?
      </h5>
    ),
    child: (
      <p className="pad">
        Email:
        <Anchor
          id="MailToPromo"
          href="mailto:tech@ttylevents.com"
          title="tech@tekbeatz.com"
          classNames="light-green-text pad-lr"
        />
      </p>
    ),
  },
  // {
  //   child: (
  //     <Anchor
  //       id="ReadOurTerns"
  //       classNames="btn-primary btn-large black fluid"
  //       title="Terms & Conditions"
  //       to="terms"
  //     />
  //   )
  // }
]

const Contact = () => (
  <section id="ContactUsWrapper" className="margin-tb-2">
    <HeaderBar
      id="ContactUsHeader"
      level={4}
      classNames="row left-align black raised margin-0"
    >
      Contact Us
    </HeaderBar>
    <div className="margin-tb-2">
      <Accordion id="ContactUsContainer" items={items} />
    </div>
  </section>
);

export default Contact;
