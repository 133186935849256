import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import TTYLEventsOld from './TTYLEventsOld';

import Eventbrite from './Eventbrite';

import proptypes from '../proptypes';

import Anchor from '../Anchor';
import Text from '../Text';

const COMPONENT_NAME = 'ShowsListItem';

const ListItem = ({
  showid,
  eventid, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  eventbrite,// EVENTBRITE API Listing
  host,
  title,
  name, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  flyer,
  artists,
  genres,
  datetime,
  posted,
  tickets, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
  vip,
  venue,
  description,
  disclaimer,
  url,
  view,
  edit,
  isTest,
}) => (
  (!!eventbrite && (
    <Eventbrite showid={showid} {...eventbrite} />
  )) || (
    (!!eventid && !!name && !!tickets)
      ?
      <TTYLEventsOld {...{
        name,
        eventid,
        host,
        flyer,
        artists,
        genres,
        datetime,
        posted,
        tickets,
        vip,
        description,
        disclaimer,
        view,
        edit,
        isTest,
      }} />
      :
      <div
        data-module-name={COMPONENT_NAME}
        className={
          `col s12 m6 show-wrapper margin-t-0 ${
            isTest ? 'yellow' : ''
          }`
        }
      >
        <Anchor
          id={`To-${showid}`}
          href={url}
          to={`/show/${showid}`}
          title={`Link To: ${title} Hosted By: ${host}`}
        >
          <div className="valign-wrapper white-text">
            <div className="row margin-0">
              <div className="col s12 margin-b-1">
                <div className="row margin-0">
                  <div
                    className="col s4 black center-align raised show-date"
                    style={{ height: '54px' }}
                  >
                    <div className="row margin-0">
                      <div className="col s12">
                        { moment(datetime).format('MMMM') }
                      </div>
                      <div className="col s12 font-size-3">
                        { moment(datetime).format('DD') }
                      </div>
                    </div>
                  </div>
                  <div className="col s8 ttyl-grey valign-wrapper raised show-host">
                    { !!host && (
                      <div className="fluid center-align">
                        <Text classNames="card-title font-size-1">
                          { host }
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col s12">
                { !!title && (
                  <div className="valign-wrapper show-title ttyl-grey raised pad-0">
                    <div className="fluid center-align">
                      <Text classNames="card-title font-size-2">
                        { title }
                      </Text>
                    </div>
                  </div>
                )}
              </div>
              { flyer && (
                <div className="col s12 waves-effect waves-light margin-b-1 raised">
                  <img
                    id={`${title} Flyer`}
                    className="fluid"
                    alt={`${title}-Flyer`}
                    src={flyer}
                    style={{
                      maxWidth: '100%',
                      position: 'relative',
                      bottom: '-5px',
                    }}
                  />
                </div>
              )}
              {venue && (
                <div className="col s12 waves-effect waves-light margin-b-1">
                  {venue.name && (
                    <div className="fluid center-align black margin-b-1 raised">
                      { venue.name }
                    </div>
                  )}
                  {venue.location && (
                    <div className="fluid center-align ttyl-grey raised">
                      <Text classNames="font-size-1">
                        { venue.location }
                      </Text>
                    </div>
                  )}
                </div>
              )}
              {posted && (
                <div className="col s12 waves-effect waves-light fluid center-align black margin-b-1 raised">
                  <Text classNames="font-size-1">
                    Posted: { moment(posted).format('MM.DD.YYYY') }
                  </Text>
                </div>
              )}
            </div>
          </div>
        </Anchor>
      </div>
  )
);

ListItem.displayName = COMPONENT_NAME;

ListItem.propTypes = {
  edit: proptypes.typeCheck.bool,
  showid: proptypes.typeCheck.string,
  genre: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.arrOfObj,
  datetime: proptypes.typeCheck.string,
  posted: proptypes.typeCheck.string,
  tickets: proptypes.typeCheck.obj,
  vip: proptypes.typeCheck.arrOfObj,
  description: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  view: PropTypes.bool,
  venue: proptypes.typeCheck.obj
};

ListItem.defaultProps = {
  edit: false,
  showid: false,
  genre: false,
  host: false,
  title: false,
  flyer: false,
  artists: false,
  datetime: false,
  posted: false,
  tickets: false,
  vip: false,
  description: false,
  disclaimer: false,
  view:  false,
  venue: false
};

export default ListItem;
