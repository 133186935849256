import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Video = ({
  src,
  type,
  width,
  height,
  autoplay,
  loop,
  muted,
  poster,
  preload,
  controls,
  classNames,
  styles,
}) => (
  <video
    width={width}
    height={height}
    controls={controls}
    poster={poster}
    preload={preload}
    autoPlay={autoplay}
    muted={muted}
    className={classNames}
    style={styles}
  >
    <source src={src} type={type} />
    Your browser does not support the video tag.
  </video>
);

Video.propTypes = {
  type: PropTypes.string,
  controls: PropTypes.bool,
  autoplay: PropTypes.string,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  poster: PropTypes.string,
  preload: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  classNames: PropTypes.string,
  styles: PropTypes.shape({}),
};

Video.defaultProps = {
  autoplay: 'autoplay',
  controls: true,
  loop: true,
  muted: true,
  poster: '',
  type: 'video/mp4',
  preload: 'auto',
  width: '100%',
  height: '100%',
  classNames: '',
  styles: {},
};

export default memo(Video);
