import React, { Fragment } from 'react';

import Icon from './components/Icon';
import Text from './components/Text';

const Promotion = ({ host, promote, marketing }) => (
  promote
    ?
    <Fragment>
      <div className="col s12 m6 l4 show-wrapper margin-t-0">
        <button
          id="TogglePromoteForTTYLEventsNow"
          title="Click here to learn more about promoting with TTYL Events!"
          style={{
            padding: 0,
            background: 'none',
            border: 'none',
          }}
        >
          <div className="valign-wrapper white-text">
            <div className="row margin-0">
              <div
                className="col s4 waves-effect waves-light black bolded raised pad margin-b-1"
                style={{ height: '53px' }}
              >
                <Icon
                  icon="keyboard_arrow_right"
                  styles={{ position: 'relative', top: '5px' }}
                />
              </div>
              <div
                className="col s8 waves-effect waves-light ttyl-grey bolded raised margin-b-1"
                style={{ padding: '15px', height: '53px' }}
              >
                <div className="fluid center-align">
                  <Text classNames="card-title font-size-1">
                    TTYL Presents
                  </Text>
                </div>
              </div>
              <div className="col s12 margin-b-1 pad-t-0">
                <div className="valign-wrapper show-title ttyl-grey raised pad">
                  <div className="fluid center-align">
                    <Text classNames="card-title font-size-2">
                      Promote for TTYL Events
                    </Text>
                  </div>
                </div>
              </div>
              <div className="col s12 waves-effect waves-light">
                <img
                  id="Promote For TTYL Image"
                  className="raised fluid"
                  alt="Promote for TTYL Events"
                  src="https://storage.googleapis.com/ttylevents-cdn/events/CID2019_0414_020743-5634-CCL.jpeg"
                  style={{
                    width: '100%',
                  }}
                />
              </div>
              <div
                className="col s12 waves-effect waves-light black raised margin-b-1"
                style={{ padding: '0 5px' }}
              >
                <p className="fluid center-align font-size-1">
                  Do you love our events? Do you love bringing your friends to our events? Sign up for a chance to join TTYL Events as a promoter!
                </p>
              </div>
              <div
                className="col s12 waves-effect waves-light black bolded raised margin-b-1"
                style={{ padding: '15px', height: '53px' }}
              >
                <div className="fluid center-align">
                  <Text classNames="card-title font-size-1">
                    Find out more!
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
      {/*
        <div className="col s12 m6 l4 show-wrapper margin-t-0">
          <button
            id="TogglePromoteForTTYLEventsNow"
            title="Click here to learn more about promoting with TTYL Events!"
            style={{
              padding: 0,
              background: 'none',
              border: 'none',
            }}
          >
            <div className="valign-wrapper white-text">
              <div className="row margin-0">
                <div className="col s12 waves-effect waves-light">
                  <img
                    id="Promote For TTYL Image"
                    className="raised fluid"
                    alt="Promote for TTYL Events"
                    src="https://storage.googleapis.com/ttylevents-cdn/events/CID_4_13_2019/CID2019_0414_013806-4780-CCL.jpg"
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
                <div className="col s12 waves-effect waves-light">
                  <img
                    id="Promote For TTYL Image"
                    className="raised fluid"
                    alt="Promote for TTYL Events"
                    src="https://storage.googleapis.com/ttylevents-cdn/events/CID_4_13_2019/CID2019_0414_013710-4714-CCL.jpg"
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
          </button>
        </div>
        */}
    </Fragment>
    :
    null // <Marketing marketing={marketing} />
);

export default Promotion;
