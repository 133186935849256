import React, { Fragment, PureComponent } from 'react';

import Data from '../Functions/Shows';

import Artist from './Artist';
import Promoter from './Promoter';
import Venue from './Venue';
import Shows from './Shows';

import Anchor from './components/Anchor';
import Accordion from './components/Accordion';
import Button from './components/Button';
import Header from './components/Header';
import Icon from './components/Icon';
import Tabs from './components/Tabs';
import Text from './components/Text';

import ManageShow from './components/Forms/ManageShow';
import Input from './components/Fields/Input';

const { testShows } = Data;

const showAdminTabs = [
  {
    icon: 'info',
    title: 'Info',
    child: (
      <section id="ShowsAdminInfo" className="pad">
        <h5>
          Welcome to the Shows Admin Panel.
        </h5>
        <p>You may edit, add, or delete your managed shows here!</p>
        <p>Please select from the above tabs to start your event journey!</p>
      </section>
    ),
    selected: true,
    disabled: false,
  },
  {
    icon: 'bookmarks',
    title: 'Manage',
    child: (
      <Fragment>
        <ManageShow id="ShowsAdminCreateShow" typeForm="Edit" />
        <Shows shows={testShows} edit={true} />
      </Fragment>
    ),
    selected: false,
    disabled: false,
  },
  {
    icon: 'add',
    title: 'Create',
    child: (
      <ManageShow id="ShowsAdminCreateShow" typeForm="Create" />
    ),
    selected: false,
    disabled: false,
  },
]

const accProps = [
  {
    header: (
      <Header level={5}>
        <Icon icon="chevron_right" classNames="margin-r font-size-2 left" />
        Shows
        <Icon icon="event" classNames="margin-r font-size-2 right" />
      </Header>
    ),
    child: (
      <section id="ShowsAdmin" className="fluid">
        <Tabs id="ShowAdminTabs" tabs={showAdminTabs} />
      </section>
    ),
  },
  {
    header: (
      <Header level={5}>
        <Icon icon="chevron_right" classNames="margin-r font-size-2 left" />
        Venue
        <Icon icon="business" classNames="margin-r font-size-2 right" />
      </Header>
    ),
    child: (
      <Venue />
    ),
  },
  {
    header: (
      <Header level={5}>
        <Icon icon="chevron_right" classNames="margin-r font-size-2 left" />
        Artist
        <Icon icon="portrait" classNames="margin-r font-size-2 right" />
      </Header>
    ),
    child: (
      <Artist />
    ),
  },
  {
    header: (
      <Header level={5}>
        <Icon icon="chevron_right" classNames="margin-r font-size-2 left" />
        Promoter
        <Icon icon="recent_actors" classNames="margin-r font-size-2 right" />
      </Header>
    ),
    child: (
      <Promoter />
    ),
  },
];

const CompanyLogo = ({ title, logo, editing }) => (
  <div className="col s12 m4 l3" style={{ height: '100%' }}>
    { !!editing && (
      <Fragment>
        <Input type="text" fieldId="logo" label="Logo of the Company" required value={logo} />
      </Fragment>
    )}
    <img
      title={title}
      src={logo}
      alt={title}
      style={{ width: '100%' }}
    />
  </div>
);

const CompanyInfo = ({
  title,
  mission,
  theme,
  socials,
  sounds,
  url,
  editing,
}) => (
  <div
    className={`${theme.foreground} col s12 m8 l9`}
    style={{ height: '100%', margin: '10px 0' }}
  >
    { !!editing
        ?
        <Fragment>
          <Input type="text" fieldId="title" label="Title of the Company" required value={title} />
          <Input type="text" fieldId="url" label="Website or Url of the Company" required value={url} />
        </Fragment>
        :
        <Anchor
          id="TitleLinkCompany"
          href={url}
          title={title}
          target="blank"
        >
          <h5>{title}</h5>
        </Anchor>
    }
    { !!editing
        ?
        <Fragment>
          <Input type="text" fieldId="mission" label="Mission of the Company" required value={mission} />
        </Fragment>
        :
        <p className={`${theme.text}-text pad`}>
          {mission}
        </p>
    }
    { false && !!socials && (
      <p className="pad">
        <Fragment>
          <Anchor
            id="facebook"
            href={socials.facebook}
            className="chip black white-text raised"
          >
            {false && <Icon icon="facebook" />}
            facebook
          </Anchor>
          <Anchor
            id="instagram"
            href={socials.instagram}
            className="chip black white-text raised"
          >
            {false && <Icon icon="instagram" />}
            instagram
          </Anchor>
          <Anchor
            id="twitter"
            href={socials.twitter}
            className="chip black white-text raised"
          >
            {false && <Icon icon="twitter" />}
            twitter
          </Anchor>
        </Fragment>
      </p>
    )}
  </div>
)

const CompanyBanner = ({
  title,
  logo,
  theme,
  mission,
  socials,
  sounds,
  url,
  editing,
}) => (
  <div className="ttyl-grey raised">
    <div className={`${theme.background} row`} style={{ margin: 0 }}>
      <CompanyLogo title={title} logo={logo} editing={editing} />
      <CompanyInfo
        title={title}
        mission={mission}
        theme={theme}
        socials={socials}
        sounds={sounds}
        url={url}
        editing={editing}
      />
    </div>
  </div>
);

class Admin extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  submitEdit = (company) => console.log(company);

  render() {
    const { auth, user } = this.props;
    const { editing, company } = this.state;
    return (
      auth ? (
        <Fragment>
          <nav className="nav-extended">
            <div className="nav-content pad">
              <span className="nav-title">
                {`${user.company.title}'s`} <Text
                  classNames="ttyl-blue-text"
                >
                  Shows Admin
                </Text>
              </span>
              { !!editing && (
                <Button
                  id="SubmitCompanyUpdate"
                  title="Update"
                  classNames="btn-floating btn-large halfway-fab waves-effect waves-light green"
                  styles={{ right: '100px' }}
                  onClick={() => this.submitEdit(company)}
                >
                  <i className="material-icons">{'add'}</i>
                </Button>
              )}
              { !!editing && (
                <Button
                  id="CancelCompanyUpdate"
                  title="Cancel Update"
                  classNames="btn-floating btn-large halfway-fab waves-effect waves-light red"
                  onClick={this.toggleEditing}
                >
                  <i className="material-icons">{'cancel'}</i>
                </Button>
              )}
              { !editing && (
                <Button
                  id="EditCompanyUpdate"
                  title="Edit"
                  classNames="btn-floating btn-large halfway-fab waves-effect waves-light ttyl-blue"
                  onClick={this.toggleEditing}
                >
                  <i className="material-icons">edit</i>
                </Button>
              )}
            </div>
          </nav>
          <CompanyBanner {...user.company} editing={editing} />
          <Accordion
            id="ShowsAcc"
            items={accProps}
            styles={{ margin: 0 }}
          />
        </Fragment>
      ) : null
    );
  }
}

Admin.displayName = 'Admin';

export default Admin;
