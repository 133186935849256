const sec = 'https://';
const domain = 'tekbeatz.com';

const APP = {
  // APP Domains
  sec,
  domain,
  domainFull: (sec + domain),
};

const APPNAME = 'TEK || tekbeatz';

const COLORS = {
  main: 'black',
  second: 'black',
  third: 'white',
};

const SOCIAL = {
  instagram: (
    'https://www.instagram.com/tekbeatz/'
  ),
};

const storage = (
  'https://storage.googleapis.com/tekbeatz-cdn'
);

const assets = (
  storage + '/assets'
);

const images = (
  assets + '/images'
);

const DARK_LOGO = (
  images + '/tek_logo_white.jpg'
);

const LIGHT_LOGO = (
  images + '/tek_logo_black.jpg'
);

const LOGO = (
  images + '/tek_logo_recursion.jpg'
);

const BANNER = (
  images + '/blackwhite.jpg'
);

export default {
  APP,
  APPNAME,
  COLORS,
  ASSETS: {
    LOGO,
    DARK_LOGO,
    LIGHT_LOGO,
    BANNER,
  },
  SOCIAL,
};
