import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Analytics from './Analytics';
import Customers from './Customers';
import Admin from './Admin';

import NonAuthRender from './NonAuthRender';

import Button from './components/Button';
import Info from './components/Info';
import Preloader from './components/Preloader';
import Tabs from './components/Tabs';
import Text from './components/Text';

import ManageUser from './components/Forms/ManageUser';

import CUSTOMERS from '../Functions/Customers';
import USERS from '../Functions/Users';

const { testCustomers } = CUSTOMERS;
const { testUser } = USERS;

const COMPONENT_NAME = 'MyAccount';

class MyAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      editing: false,
    };
  }

  componentDidMount = () => this.load(this.props);

  load = ({ id, auth }) => (
    auth
      ?
      this.setState({
        auth,
        user: {
          title: 'Personal Information',
          ...testUser,
        },
        customers: testCustomers,
        loading: false,
      })
      :
      this.setState({ auth, user: {}, customers: [], loading: false })
  );

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  submitEdit = (user) => console.log(user);

  render() {
    const { styles } = this.props;
    const { user, auth, customers, loading, editing } = this.state;

    const tabsProps = [
      {
        icon: 'account_box',
        title: 'Account',
        child: (
          !!user && (
            <section id="Account Portal">
              <nav className="nav-extended">
                <div className="nav-content pad">
                  { !!user.firstname && user.lastname && (
                    <span className="nav-title">
                      {`${user.firstname} ${user.lastname}'s`} <Text
                        classNames="ttyl-blue-text"
                      >
                        Account
                      </Text>
                    </span>
                  )}
                  { !!editing && (
                    <Button
                      id="SubmitUserUpdate"
                      title="Update"
                      classNames="btn-floating btn-large halfway-fab waves-effect waves-light green"
                      styles={{ right: '100px' }}
                      onClick={() => this.submitEdit(user)}
                    >
                      <i className="material-icons">{'add'}</i>
                    </Button>
                  )}
                  { !!editing && (
                    <Button
                      id="CancelUserUpdate"
                      title="Cancel Update"
                      classNames="btn-floating btn-large halfway-fab waves-effect waves-light red"
                      onClick={this.toggleEditing}
                    >
                      <i className="material-icons">{'cancel'}</i>
                    </Button>
                  )}
                  { !editing && (
                    <Button
                      id="EditUserUpdate"
                      title="Edit"
                      classNames="btn-floating btn-large halfway-fab waves-effect waves-light ttyl-blue"
                      onClick={this.toggleEditing}
                    >
                      <i className="material-icons">edit</i>
                    </Button>
                  )}
                </div>
              </nav>
              { !!editing
                  ?
                  <ManageUser id="UserManageProfile" typeForm="Edit" {...{user, auth}} />
                  :
                  <Info {...user} auth={auth} />
              }
            </section>
          )
        ),
        selected: true,
        disabled: false,
      },
      {
        icon: 'event',
        title: 'Admin',
        child: (
          <Admin auth={auth} user={user} />
        ),
        selected: false,
        disabled: false,
      },
      {
        icon: 'contacts',
        title: 'Customers',
        child: (
          <Customers
            user={user}
            auth={auth}
            customers={customers}
          />
        ),
        selected: false,
        disabled: false,
      },
      {
        icon: 'show_chart',
        title: 'Analytics',
        child: (
          <Analytics />
        ),
        selected: false,
        disabled: false,
      },
    ];
    return (
      auth
        ?
        (loading
          ?
          (
            <Preloader />
          )
          :
          (
            <section id={COMPONENT_NAME}>
              <div style={{ padding: 0, ...styles, }}>
                {!!user && (
                  <Tabs
                    id="AccountTabs"
                    tabs={tabsProps}
                  />
                )}
              </div>
            </section>
          )
        )
        :
        <NonAuthRender />
    );
  }
}

MyAccount.displayName = COMPONENT_NAME;

const mapStateToProps = (state, { auth }) => ({ auth });

export default connect(mapStateToProps)(MyAccount);
