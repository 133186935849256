const testCustomers = [
  {
    firstname: 'Chris',
    lastname: 'Neuert',
  },
  {
    firstname: 'Larissa',
    lastname: 'Neuert',
  },
  {
    firstname: 'Chase',
    lastname: 'Neuert',
  },
  {
    firstname: 'Grace',
    lastname: 'Neuert',
  },
];

export default {
  testCustomers,
};
