import React from 'react';

import Anchor from './components/Anchor';
import config from '../Config/index.js';

const { APPNAME, COLORS, ASSETS } = config;
const { LIGHT_LOGO } = ASSETS;

const Footer = ({ style }) => (
  <section id="Footer" style={style}>
    <footer className={`${COLORS.second} page-footer`}>
      <div className="container">
        <div className="row">
          <Anchor
            id="LinkToHomeFooter"
            title="Home"
            to="/"
            classNames="brand-logo left waves-effect waves-light"
            styles={{ padding: '0', height: '100%' }}
          >
            <img
              src={LIGHT_LOGO}
              alt="TTYL Events Logo"
              style={{
                height: '60px',
                position: 'relative',
                top: '0',
              }}
            />
          </Anchor>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          © 2018 { APPNAME }
          { false && (
            <Anchor
              id="FooterLinkToTerms"
              title="Our Terms & Conditions"
              classNames="grey-text text-lighten-4 right"
              to="/terms"
            />
          )}
        </div>
      </div>
    </footer>
  </section>
);

export default Footer;
