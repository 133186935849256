import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';

import Anchor from '../Anchor';
import Button from '../Button';
import Modal from '../Modal';

import proptypes from '../proptypes';

import Text from '../Text';

const COMPONENT_NAME = 'FullListItem';

const Header = ({ datetime, host, title }) => (
  <div className="row margin-b">
    <div className="col s12">
      <div className="row margin-b">
        { !!datetime && (
          <div
            className="col s4 black white-text center-align raised show-date"
            style={{ height: '54px' }}
          >
            <div className="row margin-0">
              <div className="col s12">
                { moment(datetime).format('MMMM') }
              </div>
              <div className="col s12 font-size-3">
                { moment(datetime).format('DD') }
              </div>
            </div>
          </div>
        )}
        { !!host && (
          <div className="col s8 ttyl-grey valign-wrapper raised show-host">
            <div className="fluid center-align">
              <Text classNames="card-title font-size-2">
                { host }
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
    { !!title && (
      <div className="col s12 card-title ttyl-grey raised pad-tb">
        <Text classNames="font-size-3" styles={{ lineHeight: '2rem' }}>
          { title }
        </Text>
      </div>
    )}
  </div>
);

const Tags = ({ artists, genres }) => (
  <Fragment>
    <div className="row margin">
      { !!artists && (
        artists.map(({ name, website }) => (
          <Anchor
            id={website}
            title={name}
            href={website}
            key={JSON.stringify(name)}
            target="_blank"
          >
            <div className="chip black white-text raised bolded">
              {name}
            </div>
          </Anchor>
        ))
      )}
    </div>
    <div className="row margin">
      { !!genres && (
        genres.map(genre => (
          <div className="chip raised bolded" key={JSON.stringify(genre)}>
            {genre}
          </div>
        ))
      )}
    </div>
  </Fragment>
);

const Flyer = ({ title, flyer }) => (
  !!flyer && (
    <div className="col s12 m6 pad-0">
      <div className="row margin-0">
        <img
          id={`${title} Flyer`}
          className="raised fluid"
          alt={`${title} Flyer`}
          src={flyer}
        />
      </div>
    </div>
  )
);

const Purchase = ({
  title,
  showid,
  func,
  url,
  soldOut,
  ownPurchaseChildren,
  edit,
}) => (
  <Fragment>
    {
      ownPurchaseChildren ||
      <div className="margin-tb">
        { !!func && !!func.run && !!showid && (
          <Button
            classNames={func.classNames || 'btn green fluid raised'}
            onClick={() => func.run(showid)}
            styles={{ height: '50px' }}
          >
            {func.text || 'Get Ticket(s)'}
          </Button>
        )}
        { !!url && !!url.href && (
          <Anchor
            classNames={url.classNames || 'btn black fluid raised'}
            href={!!edit ? url.href : `${url.href}/edit`}
            styles={{ height: '50px' }}
          >
            {url.text || 'Get Ticket(s)'}
          </Anchor>
        )}
        { !!soldOut && !!title && (
          <Button
            classNames={soldOut.classNames || 'btn red fluid raised'}
            onClick={() => (
              !!edit
                ?
                `${url.href}/edit`
                :
                alert(
                  `${title} is sold out! Please check out our other events!`
                )
            )}
            styles={{ height: '50px' }}
          >
            {soldOut.text || 'Sold Out'}
          </Button>
        )}
      </div>
  }
  </Fragment>
);

const Venue = ({ venue: { name, location, website }, title, host }) => (
  name ? (
    <div className="row margin-b">
      {name && (
        <div className="fluid center-align black">
          {website ? (
            <Anchor
              id={website}
              href={website}
              title={`Link To: ${title} Hosted By: ${host}`}
            >
              { name }
            </Anchor>
          ) : name}
        </div>
      )}
      {location && (
        <div className="fluid center-align ttyl-grey">
          <Text classNames="font-size-1">
            { location }
          </Text>
        </div>
      )}
    </div>
  ) : null
);

const Description = ({ title, description, purchase, showid }) => (
  !!description && (
    <div className="col s12 m6 pad-0 margin-b">
      {
        (description.length > 200)
          ?
          <Fragment>
            <div
              className="row ttyl-grey show-desc extended margin-0 pad-2"
              style={{
                padding: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '25vh',
                zIndex: 0,
              }}
            >
              { description }
            </div>
            <Modal
              id="FullShowDescriptionModal"
              open="Read More"
            >
              { !!title && (
                <div className="fluid card-title black pad-tb margin-t pad-0">
                  <Text classNames="font-size-3" styles={{ lineHeight: '2rem' }}>
                    { title }
                  </Text>
                </div>
              )}
              <div className="fluid pad-0">
                <p className="card-info pad-2 black">
                  { description }
                </p>
              </div>
            </Modal>
          </Fragment>
          :
          <div
            className="row ttyl-grey show-desc margin-0 pad-2"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: '25vh',
              zIndex: 0,
            }}
          >
            { description }
          </div>
      }
    </div>
  )
);

const Content = ({
  title, artists, genres, flyer, description, purchase, showid, edit,
}) => (
  <div className="row margin-b">
    <Purchase {...purchase} title={title} showid={showid} edit={edit} />
    <Tags genres={genres} artists={artists} />
    <Flyer title={title} artists={artists} genres={genres} flyer={flyer} />
    <Description title={title} description={description} purchase={purchase} showid={showid} />
    <Purchase {...purchase} title={title} showid={showid} edit={edit} />
  </div>
);

const Footer = ({disclaimer, posted }) => (
  <div className="row ttyl-grey raised">
    { !!disclaimer && (
      <div className="col s12 white-text pad">
        <Text>
          { disclaimer }
        </Text>
      </div>
    )}
    { !!posted && (
      <div className="col s12 secondary-content pad">
        <Text>
          Posted: { moment(posted).format('MMMM Do YYYY') }
        </Text>
      </div>
    )}
  </div>
);

class FullItem extends PureComponent {
  render() {
    const {
      showid,
      eventid, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
      host,
      title,
      name, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
      flyer,
      artists,
      genres,
      datetime,
      posted,
      tickets, // DEPRECATED SHOWS FROM TTYL EVENTS BETA
      // vip,
      description,
      disclaimer,
      purchase,
      venue,
      edit,
    } = this.props;
    return (
      <div className="col s12">
        <div className="center-align">
          <Header datetime={datetime} host={host} title={name || title} />
          <Venue venue={venue} title={title} host={host} />
          <Content
            title={name || title}
            artists={artists}
            genres={genres}
            flyer={flyer}
            description={description}
            purchase={tickets || purchase}
            showid={eventid || showid}
            edit={edit}
          />
          <Footer disclaimer={disclaimer} posted={false || posted} />
        </div>
      </div>
    );
  }
}

FullItem.displayName = COMPONENT_NAME;

FullItem.propTypes = {
  showid: proptypes.typeCheck.string,
  genre: proptypes.typeCheck.string,
  host: proptypes.typeCheck.string,
  title: proptypes.typeCheck.string,
  flyer: proptypes.typeCheck.string,
  artists: proptypes.typeCheck.arrOfObj,
  datetime: proptypes.typeCheck.string,
  posted: proptypes.typeCheck.string,
  tickets: proptypes.typeCheck.obj,
  vip: proptypes.typeCheck.arrOfObj,
  description: proptypes.typeCheck.string,
  disclaimer: proptypes.typeCheck.string,
  purchase: proptypes.typeCheck.obj,
};

FullItem.defaultProps = {
  showid: false,
  genre: false,
  host: false,
  title: false,
  flyer: false,
  artists: false,
  datetime: false,
  posted: false,
  tickets: false,
  vip: false,
  description: false,
  disclaimer: false,
  purchase: false,
};

export default FullItem;
