import React, { PureComponent, Fragment, memo } from 'react';
import { connect } from 'react-redux';

import HeaderBar from './components/HeaderBar';
import Preloader from './components/Preloader';
import ShowsComponent from './components/Shows';
import Text from './components/Text';

import SHOWS from '../Functions/Shows';

const { getShows } = SHOWS;

const COMPONENT_NAME = 'Shows';

const ShowsDataLayer = memo(({
  edit,
  id,
  shows,
  pastShows,
  styles,
  loading,
  auth,
  user,
  header,
}) => (
  <section id={`${COMPONENT_NAME}-DataLayer`} className="margin-tb">
    {
      loading
        ?
        <Preloader />
        :
        <Fragment>
          { header && (
            <HeaderBar
              id="UpcomingShowsHeader"
              classNames="row left-align black raised margin-tb-2 margin-lr-0"
              level={4}
            >
              <Text>Upcoming Shows</Text>
            </HeaderBar>
          )}
          {(!!shows && shows.length > 0) ? (
            <div className="row uppercase">
              <ShowsComponent
                showid={id}
                shows={shows}
                auth={auth}
                user={user}
                edit={edit}
              />
            </div>) : (
            !id ? (
              <Text
                classNames="margin-lr center"
                title="No upcoming shows,
                 to hear about future shows,
                  please sign up above for updates."
              />
            ) : null)
          }
          { header && (pastShows.length > 0) && (
            <HeaderBar
              id="UpcomingShowsHeader"
              classNames="row left-align black raised margin-tb-2 margin-lr-0"
              level={4}
            >
              Past Shows
            </HeaderBar>
          )}
          <div className="row uppercase">
            <ShowsComponent
              showid={id}
              pastShows={pastShows}
              auth={auth}
              user={user}
              edit={edit}
            />
          </div>
        </Fragment>
    }
  </section>
));

ShowsDataLayer.displayName = `${COMPONENT_NAME}-DataLayer`;

class TheShows extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      id: false,
      auth: false,
      shows: [],
      loading: true,
      user: false,
    };
  }

  componentDidMount = () => this.load(this.props);

  load = ({ id, auth, user, edit }) => {
    getShows({ edit, id, auth, user }).then(([past, present]) => (
      this.setState({
        edit,
        id,
        auth,
        user,
        shows: (
          (!!past && past.length > 0) ? past : []
        ),
        pastShows: (
          (!!present && present.length > 0) ? present : []
        ),
        loading: false,
      })
    ));
  };

  render = () => (<ShowsDataLayer { ...this.state } { ...this.props} />);
}

TheShows.displayName = `${COMPONENT_NAME}-Provider`;

const mapStateToProps = (state, { edit, id, auth, user, header }) => ({
  edit, id, auth, user, header,
});

export default connect(mapStateToProps)(TheShows);
