import React from 'react';

// NOT LINKED TO Mailchimp
const Promote = () => (
  false && <div
   id="PromoteFortekbeatzNow"
   className="ttyl-modal"
   style={{ display:'none' }}
 >
   <div id="BackgroundPromoteFortekbeatzNow" className="ttyl-modal-background"></div>
   <div className="ttyl-modal-content container pad-tb">
     <div className="margin-t margin-b-2 center-align">
       <button id="ClosePromoteFortekbeatzNowTop" className="btn btn-primary btn-large fluid">
         Close
       </button>
     </div>
     {/* <!--Start mc_embed_signup--> */}
     <div id="mc_embed_signup">
       <form action="https://tekbeatz.us17.list-manage.com/subscribe/post?u=12366df668e5cce2ce869ed7a&amp;id=c7e741f5ee" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
         <div id="mc_embed_signup_scroll">
           <h2>Learn more about Promoting for TEK || tekbeatz!</h2>
           <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
           <div className="mc-field-group">
             <label htmlFor="mce-EMAIL">
               Email Address  <span className="asterisk">*</span>
            </label>
           <input type="email"  name="EMAIL" className="required email" id="mce-EMAIL" />
           </div>
           <div className="mc-field-group">
             <label htmlFor="mce-FNAME">First Name  <span className="asterisk">*</span>
           </label>
           <input type="text"  name="FNAME" className="required" id="mce-FNAME" />
         </div>
         <div className="mc-field-group">
           <label htmlFor="mce-LNAME">
             Last Name
           <span className="asterisk">*</span>
         </label>
         <input type="text"  name="LNAME" className="required" id="mce-LNAME" />
       </div>
       <div className="mc-field-group size1of2">
         <label htmlFor="mce-PHONE">
           Phone Number
           <span className="asterisk">*</span>
         </label>
         <input type="text" name="PHONE" className="required"  id="mce-PHONE" />
       </div>
       <div className="mc-field-group">
         <label htmlFor="mce-FACEBOOK">Facebook </label>
         <input type="url"  name="FACEBOOK" className=" url" id="mce-FACEBOOK" />
       </div>
       <div className="mc-field-group">
         <label htmlFor="mce-INSTAGRAM">Instagram </label>
         <input type="url"  name="INSTAGRAM" className=" url" id="mce-INSTAGRAM" />
       </div>
       <div className="mc-field-group">
         <label htmlFor="mce-TWITTER">Twitter </label>
         <input type="url"  name="TWITTER" className=" url" id="mce-TWITTER" />
       </div>
       <div className="mc-field-group">
         <label htmlFor="mce-WEBSITE">Website </label>
         <input type="url"  name="WEBSITE" className=" url" id="mce-WEBSITE" />
       </div>
       <div id="mce-responses" className="clear">
         <div className="response" id="mce-error-response" style={{display:'none'}}></div>
         <div className="response" id="mce-success-response" style={{display:'none'}}></div>
       </div>
       <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
         <input type="text" name="b_12366df668e5cce2ce869ed7a_c7e741f5ee" tabIndex="-1" />
       </div>
       <div className="clear">
         <input type="submit" value="Sign Up" name="signup" id="mc-embedded-subscribe" className="btn btn-primary btn-large margin-0" />
       </div>
     </div>
   </form>
 </div>
 {/* <!--End mc_embed_signup--> */}
 <div className="margin-t-2 center-align">
   <button
     id="ClosePromoteFortekbeatzNowBottom"
     className="btn btn-primary btn-large fluid"
   >
    Close
   </button>
  </div>
 </div>
   </div>
);

export default Promote;
