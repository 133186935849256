import React from 'react';

import Anchor from './components/Anchor';
import Text from './components/Text';

const Customers = ({ auth, user, customers }) => (
  <section id="Customers">
    <nav className="nav-extended">
      <div className="nav-content pad">
        { !!user && !!user.company && (
          <span className="nav-title">
            {`${user.company.title}'s`} <Text
              classNames="ttyl-blue-text"
            >
              Customers Admin
            </Text>
          </span>
        )}
        <a
          className="btn-floating btn-large halfway-fab waves-effect waves-light green"
          style={{ right: '175px' }}
          href="#!"
        >
          <i className="material-icons">{'add'}</i>
        </a>
        <a
          className="btn-floating btn-large halfway-fab waves-effect waves-light ttyl-blue"
          href="#!"
          style={{ right: '100px' }}
        >
          <i className="material-icons">{'edit'}</i>
        </a>
        <a
          className="btn-floating btn-large halfway-fab waves-effect waves-light red"
          href="#!"
        >
          <i className="material-icons">{'delete'}</i>
        </a>
      </div>
    </nav>
    <div className="collection ttyl-grey" style={{ margin: '0', padding: '45px 0' }}>
      { !!customers && customers.map(({ to, firstname, lastname, disabled }, index) => (
        <Anchor
          key={`${JSON.stringify(customers)}-${index}`}
          id={`${to}-${firstname}-${lastname}-${disabled}`}
          title={`${firstname}-${lastname}-${disabled}`}
          to={to}
          classNames={`collection-item ttyl-grey ${disabled && 'disabled'}`}
        >
          {firstname} {lastname}
        </Anchor>
      ))}
    </div>
  </section>
);

export default Customers;
