import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import proptypes from '../proptypes';

const Chart = props => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      title: { text: ((!!props && props.title) || 'Chart') },
      series: [{
        type: ((!!props && props.type) || undefined),
        data: ((!!props && props.data) || [1, 2, 3]),
      }],
    }}
  />
);

Chart.displayName = 'Chart';

Chart.propTypes = {
  title: proptypes.typeCheck.string,
  data: proptypes.typeCheck.arrOfNum,
  type: proptypes.typeCheck.string,
};

Chart.defaultProps = {
  title: false,
  data: false,
  type: undefined,
};

export default Chart;
