import React, { Fragment } from 'react';

const Street = ({ street, labels }) => (
  <div className="collection-item">
    { labels && (<b>Address: </b>) }
    { street.number && <span>{ street.number }</span> }
    { street.name && <span>{ street.name } </span> }
  </div>
);

const Lines = ({ lines }) => (
  !!lines && lines.length > 0 && lines.map((line) => (
    <div className="collection-item" key={JSON.stringify(line)}>{`${line}`}</div>
  ))
);

const CityStateCountryZip = ({
  city,
  state,
  country,
  zipcode,
  labels,
}) => (
  <Fragment>
    { labels && <div className="collection-item"><b>City: </b>{city}</div> }
    { labels && <div className="collection-item"><b>State: </b>{state}</div> }
    { labels && <div className="collection-item"><b>Country: </b>{country}</div> }
    { labels && <div className="collection-item"><b>Zip Code: </b>{zipcode}</div> }
  </Fragment>
);

const renderAddress = addy => (
  <div className="ttyl-grey col s12 m6" style={{ padding: '20px' }}>
    {
      !!addy.title
        ?
        (<div><h5>{ addy.title }</h5></div>)
        :
        null
    }
    <Street { ...addy } />
    <Lines { ...addy } />
    <CityStateCountryZip { ...addy } />
  </div>
);

const Address = addy => (
  addy ? renderAddress(addy) : null
);

export default Address;
