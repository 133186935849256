import React, { Fragment, PureComponent } from 'react';

import Anchor from './components/Anchor';
import Button from './components/Button';

import Routing from '../Routing';

import Functions from '../Functions';

import config from '../Config/index.js';

const { USERS, NAV } = Functions;

const { APPNAME, COLORS, ASSETS } = config;

const { LIGHT_LOGO } = ASSETS;

const COMPONENT_NAME = 'Navigation';

const isSameRoute = link => (
  !!link && !!(
    (Routing.location.pathname === `/${link}`) ||
    (Routing.location.pathname.includes(link) && link.length > 1)
  )
);

const links = [
  {
    name: 'Shows',
    link: '/shows',
    auth: false,
  },
  {
    name: 'Shows',
    link: '/shows',
    auth: true,
  },
  {
    name: 'Contact',
    link: '/contact',
    auth: false,
  },
  {
    name: 'Contact',
    link: '/contact',
    auth: true,
  },
];

const isHome = () => (
  isSameRoute(null) ||
  isSameRoute('/') ||
  isSameRoute('#')
);

const LineItemLink = props => (
  <li className={isSameRoute(props.link) ? 'active' : ''}>
    {!!props.href ? (
      <Anchor
        id={JSON.stringify(props)}
        title={props.name}
        href={props.link}
        classNames={
          `white-text ${
            isSameRoute(props.link) ? 'active' : ''
          } waves-effect waves-light`
        }
      />) : (
      <Anchor
        id={JSON.stringify(props)}
        title={props.name}
        to={props.link}
        classNames={
          `white-text ${
            isSameRoute(props.link) ? 'active' : ''
          } waves-effect waves-light`
        }
      />
    )}
  </li>
);

const MyAccountButton = () => (
  <Fragment>
    <li
      className={
        `hide-on-med-and-down ${isSameRoute('account') ? 'active' : ''}`
      }
    >
      <Anchor
        id="LinkToAccount"
        title="My Account"
        to="/account"
        classNames="btn btn-primary waves-effect waves-light"
      />
    </li>
    <li
      className={
        `hide-on-large-only ${isSameRoute('account') ? 'active' : ''}`
      }
      style={{
        padding: '5px',
        marginTop: '-2px',
      }}
    >
      <Anchor
        id="LinkToAccount"
        title="My Account"
        to="/account"
        classNames="btn btn-primary waves-effect waves-light"
      />
    </li>
  </Fragment>
);

const LogOutButton = () => (
  <Fragment>
    <li
      className="hide-on-large-only"
      style={{
        padding: '10px',
        marginTop: '0px',
        marginLeft: '10px',
        marginRight: '10px',
      }}
    >
      <Button
        onClick={() => USERS.logOut()}
        title="Log Out"
        classNames={
          `white btn btn-primary fluid ${COLORS.second}-text waves-effect waves-light`
        }
        styles={{ height: '48px' }}
      />
    </li>
    <li
      className="hide-on-med-and-down"
      style={{
        padding: '10px 0',
        marginTop: '-10px',
        marginLeft: '15px',
        marginRight: '15px',
      }}
    >
      <Button
        onClick={() => USERS.logOut()}
        title="Log Out"
        classNames={
          `white btn btn-primary fluid ${COLORS.second}-text waves-effect waves-light`
        }
        styles={{ height: '36px' }}
      />
    </li>
  </Fragment>
);

const LoginButton = () => (
  <li className={
      (Routing.location.pathname === '/logInOrSignUp')
        ?
        'active'
        :
        ''
    }
  >
    {false && <Anchor
      id="LinkToLoginOrSignUp"
      title="Login/Register"
      to="/logInOrSignUp"
      classNames="btn btn-primary waves-effect waves-light"
    />}
  </li>
);

const SessionButtons = ({ auth, isMobile }) => (
  <Fragment>
    { !auth && (!!isMobile
      ?
      <Fragment>
        <li className="margin-r">
          <Button
            id="ToggleMailChimpSignUpButtonMobile"
            classNames="btn btn-primary waves-effect waves-light margin-tb hide-on-large-only"
            onClick={NAV.hideSideNav}
          >
            Sign Up
          </Button>
        </li>
        {/* <li className="margin-r">
          <Button
            id="TogglePromoteFortekbeatzNowNavMobile"
            title="Promote With Us"
            onClick={NAV.hideSideNav}
          />
        </li> */}
      </Fragment>
      :
      <Fragment>
        <li className="margin-r">
          <Button
            id="ToggleMailChimpSignUpButton"
            classNames="btn btn-primary waves-effect waves-light margin-tb hide-on-med-and-down"
            onClick={NAV.hideSideNav}
          >
            Sign Up
          </Button>
        </li>
        {/* <li className="margin-l">
          <Button
            id="TogglePromoteFortekbeatzNowNav"
            title="Promote With Us"
            onClick={NAV.hideSideNav}
          />
        </li> */}
      </Fragment>
    )}
    { auth
        ?
        <Fragment>
          <MyAccountButton />
          <LogOutButton />
        </Fragment>
        :
        <LoginButton />
    }
  </Fragment>
);

const renderAuth = ({ auth }) => (
  <Fragment>
    {
      links.filter(
        (link) => (link.auth === true)
      ).map((ownLink) => (
        <LineItemLink { ...ownLink } key={JSON.stringify(ownLink)} />
      ))
    }
  </Fragment>
);

const renderUnAuth = ({ auth }) => (
  <Fragment>
    { links && (
      links.filter(
        (link) => (link.auth === false)
      ).map((ownLink) => (
        <LineItemLink { ...ownLink } key={JSON.stringify(ownLink)} />
      ))
    )}
  </Fragment>
);

const renderLinks = (props) => (
  <Fragment>
    <li className={isHome() ? 'active' : ''}>
      <Anchor
        id="LinkToHomeNav"
        title="Home"
        to={isHome() ? '#' : '/'}
        classNames={`white-text ${isHome() ? 'active' : ''} waves-effect waves-light`}
        styles={{ height: '100%' }}
      />
    </li>
    { props.auth
        ?
        renderAuth(props)
        :
        renderUnAuth(props)
    }
  </Fragment>
);

const MobileNav = props => (
  <ul
    id="mobile-menu-dropdown"
    className="side-nav right"
    style={{ maxWidth: '240px' }}
  >
    <li
      className="black hide-on-med-and-up pad-0"
      style={{
        display: 'inline-block',
        width: '100%',
        height: '58px',
      }}
    >
      <Anchor
        id="LinkToHomeMobile"
        title="Home"
        to={isHome() ? '#' : '/'}
        classNames="brand-logo left waves-effect waves-light pad-l-1"
        styles={{ height: '100%' }}
      >
        <img
          src={LIGHT_LOGO}
          alt="TTYL Events Logo"
          style={{
            height: '60px',
            position: 'relative',
            bottom: '0',
          }}
        />
      </Anchor>
    </li>
    <li
      className="black hide-on-small-only"
      style={{
        height: '64px',
        display: 'inline-block',
        width: '100%',
      }}
    >
      <Anchor
        id="LinkToHomeMobile"
        title="Home"
        to={isHome() ? '#' : '/'}
        classNames="brand-logo left waves-effect waves-light pad-l-1"
        styles={{ height: '100%' }}
      >
        <img
          src={LIGHT_LOGO}
          alt="TTYL Events Logo"
          style={{
            height: '60px',
            position: 'relative',
            bottom: '0',
          }}
        />
      </Anchor>
    </li>
    { renderLinks(props) }
    <SessionButtons auth={props.auth} isMobile={true} />
  </ul>
);

const Nav = props => (
  <div className="navbar-fixed">
    <nav>
      <div className="nav-wrapper black raised">
        <h1 style={{display:'none'}}>
          { APPNAME }
        </h1>
        <Anchor
          id="LinkToHome"
          title="Home"
          to={isHome() ? '#' : '/'}
          classNames="brand-logo left waves-effect waves-light"
          styles={{ padding: '0', height: '100%' }}
        >
          <img
            src={LIGHT_LOGO}
            alt="TTYL Events Logo"
            style={{
              height: '74px',
              position: 'relative',
              bottom: '2px',
            }}
          />
        </Anchor>
        <div className="container">
          { /* Desktop Nav */ }
          <ul className="left hide-on-med-and-down">
            { renderLinks(props) }
          </ul>
          <ul className="right hide-on-med-and-down">
            <SessionButtons auth={props.auth} />
          </ul>
        </div>
        <a
          id="MobileDeviceNavExpand"
          title="MobileDeviceNavExpand"
          href="#!"
          className="button-collapse right"
          data-activates="mobile-menu-dropdown"
          style={{ padding: '0 8px' }}
        >
          <i className="material-icons white-text">menu</i>
        </a>
      </div>
    </nav>
  </div>
);

class Navigation extends PureComponent {
  render() {
    const props = this.props;
    return (
      <section id={COMPONENT_NAME} className={props.classNames}>
        <Nav {...props} />
        { /* Mobile Nav */ }
        <MobileNav {...props} />
      </section>
    );
  }
}

Navigation.displayName = COMPONENT_NAME;

export default Navigation;
